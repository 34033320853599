import React, { useContext, useEffect, useState, useRef } from 'react';
import { graphql } from "gatsby"
import Card  from '@material-ui/core/CardContent';
import CardActionArea  from '../components/CardActionArea';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import slugify from 'slugify';

import GamesLayout from '../components/GamesLayout';
import SEO from '../components/SEO';
import SearchContext from '../components/TopBarGames/context';
import BackToTop from '../components/BackToTop';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  scroller: {
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
    boxSizing: 'content-box',
  },
  loading: {
    // bottom: 0,
    // position: 'absolute',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  }
}));

const Spacer = () => {
  const NAVIGATION_HEIGHT = 32;
  return (
    <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
  )
};

const TagCard = (props) => {
  const { tag } = props
  // const tagUrl = `/${city}/tags/${tag.contentful_id}/${(tag.slug.toLowerCase() || slugify(tag.name.toLowerCase()))}`;
  const tagUrl = `/ba/tags/${tag.contentful_id}/${(tag.slug.toLowerCase() || slugify(tag.name, {lower: true, strict: true}))}`;

  return (
    <Paper>
      <Card>
        <CardActionArea to={tagUrl}>
          <Typography component="h4" variant="h4">{tag.title}</Typography>
          <Typography component="h5" variant="h5">juegos: {tag.games.length}</Typography>
        </CardActionArea>
      </Card>
    </Paper>
  )
}

const TagsList = (props) => {
  const { tags, scroller } = props
  return (
    <Grid container id="back-to-top-anchor" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}/>
      {tags.map((tag) => {
        return (
          <Grid key={tag.contentful_id} item xs={12} sm={6} md={4} lg={3} xl={3}>
            <TagCard tag={tag} />
          </Grid>
        )
      })}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BackToTop scroller={scroller} querySelector={["#back-to-top-anchor"]} />
      </Grid>

    </Grid>
  )
}

const Page = (props) => {
  const { data, pageContext } = props;
  const { tags, seo, site } = data
  const items = tags.edges.map((t) => t.node)
  const scroller = useRef();
  const classes = useStyles();
  const [db, setDb] = useState(items);
  const description = seo.description || ''

  return (
    <>
      <SEO {...seo} {...pageContext} description={description} pathName={`/tags/`} seo={{...seo}} siteUrl={site.siteMetadata.siteUrl}/>
      <GamesLayout {...pageContext} items={items} title={` por etiqueta`} staticSearch={true} displayDataFiscal={false} displayContact={false} >
        <Spacer />
        <div ref={scroller} className={classes.scroller} style={{ height: props.height, width: props.width || '100%' }} >
          <SearchContext.Consumer>
            {search => {
              return (
              <React.Fragment>
                <TagsList tags={search.searchResult || db} scroller={scroller} />
              </React.Fragment>
            )
            }}
          </SearchContext.Consumer>
        </div>
      </GamesLayout>
    </>
  )
}

export default Page;

export const query = graphql`
  query($city: String="ba") {
      seo: contentfulComponentSeo(name: {eq: "games"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: FIXED,
            width: 600,
            height: 600
          )
          id
          title
        }
        name
      }
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      tags: allContentfulGameTag(
        sort: {fields: slug, order: ASC}
        filter: {
          game: {
            elemMatch: {
              cities: {
                elemMatch: {contentful_id: {eq: $city}}
              },
              node_locale: {eq: "es-AR"}, 
              expansion: {eq: false}
            }
          }, node_locale: {eq: "es-AR"}}
      ) {
        total: totalCount
        edges {
          node {
            slug
            title: name
            description {
              raw
            }
            id
            contentful_id
            games: game {
              id
              contentful_id
            }
          }
        }
      }
    
    }
`
